<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Statystyki Banerów</h5>
      </div>
      <div class="col-sm-12 text-sm-left col-md-6 text-md-right">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="-"
          start-placeholder="Data początkowa"
          end-placeholder="Data końcowa"
          :picker-options="pickerOptions"
          :clearable="false"
          format="yyyy-MM-dd"
          :default-value="defaultDate"
        ></el-date-picker>
      </div>
    </div>


    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <label>Projekt</label>
            <fg-input>
              <el-select v-model="filter.project_id" name="project_id" filterable clearable multiple>
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4 col-lg-3">
            <app-mh-dict-view @media_house="changeMh" :is-multiple="true"></app-mh-dict-view>
          </div>
          <div class="col-md-4 col-lg-3">
            <app-banner-search-view @banners="changeBanners"></app-banner-search-view>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12  d-flex mt-sm-0">
            <div class="d-inline-flex ml-auto">
              <el-switch
                class="my-auto mr-2 leftSwitch"
                name="showAll"
                v-model="showAll"
                active-text="Dni"
                active-color="#fd7e14"
                inactive-text="Projekty"
                inactive-color="#17a2b8"
              ></el-switch>
              <p-button type="success" @click="loadData('show')" class=" mt-auto mb-0">Pokaż</p-button>
              <!--p-button type="success" @click="loadData('excel')" class=" mt-auto mb-0">Excel</p-button-->
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="card-footer" style="display: flex; justify-content: space-between">
        <h6>
          <span>Przedział dat: </span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </h6>
      </div>
    </div>
    <div v-if="Object.keys(dataBaners).length>0">
      <div class="card">
        <div class="card-header">
          <h5>Podsumowanie</h5>
        </div>
        <appBnersMove :data-days="[dataBaners.daysSum]" :baners="[dataBaners.banersSum]" :deviceSum="dataBaners.banersSum.deviceType" :isSum="true" :dayData="false"></appBnersMove>
      </div>
    </div>
    <div v-if="showAll">
      <div class="card" v-for="day in dataBaners.days" :key="day.data">
        <div class="card-header">
          <h5>{{ day.data }}</h5>
        </div>
        <appBnersMove :data-days="day.datas" :baners="dataBaners.baners" :isSum="false" :dayData="day.data"
                      :projectData="projectList"></appBnersMove>
      </div>
    </div>
    <div v-else>
      <div class="card" v-for="project in dataBaners.projects" :key="project.projectId">
        <div class="card-header">
          <h5>{{ getProjectData(project.projectId, 'project_name') }} [{{ project.projectId }}]</h5>
        </div>
        <appBnersMove :data-days="project.datas" :baners="dataBaners.baners" :isSum="false" :dayData="false"
                      :projectData="projectList"></appBnersMove>
      </div>
    </div>

  </div>
</template>
<script>


import {
  LOAD_CUSTOMER_EFFICIENCY_EXCEL_DISPLAY,
  CUSTOMER_EFFICIENCY_DISPLAY_INVALID
} from "@/store/actions/sales/customer_efficiency_excel";


import {eventBus} from "@/main";
import moment from "moment"; // time manipulation library
import "moment/locale/pl";
import utils from "src/utils";

import {DatePicker, Option, OptionGroup, Select, Switch, Tooltip} from "element-ui";
import {BANERS_STATS_GETTER_NAME, BANERS_STATS_INVALID, BANERS_STATS_LOAD} from "@/store/actions/stats/baners_stats";
import BnersMove from "@/components/Utils4/stats/baner/BnersMove.vue";
import MhDictView from "@/components/Utils4/formsElementView/mhDict.vue";
import BannerSearchView from "@/components/Utils4/formsElementView/bnnerSearch.vue"
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "@/store/actions/questions/project_dict";
import {MAILER_LISTS_INVALID} from "@/store/actions/mailings/mailer_list";
import ProjectDict from "@/components/Utils4/formsElementView/projectDict.vue";


export default {
  components: {
    ProjectDict,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    appBnersMove: BnersMove,
    appBannerSearchView: BannerSearchView,
    appMhDictView: MhDictView
  },
  computed: {

    dataBaners() {
      let result = {};
      if (this.$store.state.baners_stats.loading_status === "success") {
        //result = Object.values(this.$store.getters[BANERS_STATS_GETTER_NAME].customersSources);
        result = this.$store.getters[BANERS_STATS_GETTER_NAME].data;
      }
      return result
    },

    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    date() {
      let result = [];
      if (this.pickrDate) {
        const newDate = this.pickrDate.split(" do ");
        if (newDate[0]) result.push(newDate[0]);
        if (newDate[1]) result.push(newDate[1]);
      }
      return result;
    },

    projectList() {
      let result = Object.values(this.$store.getters.getProjectDict);
      return result;
    },

    projectDict() {
      // lista projektów do wybrania z listy
      let projects = this.projectList
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },

  },

  data() {
    return {
      loading: false,
      showAll: false,
      filter: {
        project_id: [],
        media_house: null,
        banners: [],
      },
      pickrDate: null,
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {},
  methods: {
    changeBanners(banners) {
      this.filter.banners = banners;
    },
    getProjectData(projectId, key) {
      let result;
      this.projectList.map((project) => {
        if (project.project_id === projectId) {
          result = project[key];
        }
      });
      return result;
    },
    changeMh(media_house) {
      this.filter.media_house = media_house;
    },
    async loadData(mode) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział dat!",
          type: "warning",
        });
        return;
      }
      this.loading = true;

      if (mode) {
        this.$store.commit(PROJECT_DICT_INVALID);
        this.$store.commit(MAILER_LISTS_INVALID);
      }

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      let date = {
        date_from: moment(this.dateRange[0]).format("YYYY-MM-DD 00:00:00"),
        date_to: moment(this.dateRange[1]).format("YYYY-MM-DD 23:59:59"),
      };
      const filters = Object.assign({}, date, this.filter);

      this.$store.commit(mode === "excel" ? CUSTOMER_EFFICIENCY_DISPLAY_INVALID : BANERS_STATS_INVALID);

      await this.$store
        .dispatch(mode === "excel" ? LOAD_CUSTOMER_EFFICIENCY_EXCEL_DISPLAY : BANERS_STATS_LOAD, filters)
        .then(() => {

          let message = 'Wczytano dane';
          eventBus.$emit("notify", {
            message: message,
            type: "warning",
          });
        })
        .catch((error) => {
          let isJson;
          let msg = "";
          let err_msg;
          try {
            isJson = true;
            err_msg = JSON.parse(error.response.data.description);
          } catch (e) {
            isJson = false
          }
          if (isJson === true) {
            if ("missing" in err_msg) {
              msg = "Brak parametru: " + err_msg.missing;
            }
          } else {
            msg = error.response.data.description;
          }
          eventBus.$emit("notify", {
            message: msg,
            type: "warning",
          });
        });
      if (mode === "excel") {
        const tmpName = this.$store.getters.getCustomerEfficiencyExel.tmpname;
        const fileName = this.$store.getters.getCustomerEfficiencyExel.name;

//console.log(tmpName,fileName);
        this.downloadFile(tmpName, fileName);
      }
      this.loading = false;
    },
    downloadFile(tmpName, fileName) {
      const basePath = "https://api-contract.mobi-me.pl/download/download/";
      const userToken = this.$store.state.auth.token;
      const file_path = basePath + "?token=" + userToken + "&tmpname=" + tmpName + "&name=" + fileName;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    formatCurrency(row, column, cellValue) {
      return this.$options.filters.formatCurrency(cellValue);
    }
  },
  created() {
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    this.$store.commit(BANERS_STATS_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>
