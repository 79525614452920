<template>
  <div>
    <template>
      <el-table
        :data="dataDays"
        style="width: 100%">
        <el-table-column type="expand">
          <template v-slot="props">
            <table class="devices">
              <tr>
                <th>
                  Devices
                </th>
                <th>
                  Ile
                </th>
              </tr>
              <tr v-for="(item, index)  in getDeviceType(props.row.BannerId)"  v-if="!['event_date','click','display'].includes(index)">
                <td>{{ index }}</td>
                <td>{{ item }}</td>
              </tr>
            </table>
          </template>
        </el-table-column>
        <el-table-column v-if="!isSum"
                         prop="BannerId"
                         label="Banner Id"
                         width="180">
        </el-table-column>
        <el-table-column v-if="!isSum"
                         label="Projekt"
                         prop="ProjectID"
                         width="220">
          <template v-slot="props">
            <div>{{ getProjectData(props.row.ProjectID, 'project_name') }} [{{ props.row.ProjectID }}]</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isSum"
                         prop="MediaHouseId"
                         label="MediaHouse Id"
                         width="180">
        </el-table-column>
        <el-table-column
          label="kliki"
          prop="BannerId"
          width="180">
          <template v-slot="props">
            <div>{{ getBaanersValue(props.row.BannerId, 'click') }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="otwarcia"
          prop="BannerId"
          width="180">
          <template v-slot="props">
            <div>{{ getBaanersValue(props.row.BannerId, 'display') }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Lead"
          label="Lead"
          width="180">
        </el-table-column>
        <el-table-column
          prop="LeadF1"
          label="LeadF1"
          width="180">
        </el-table-column>
        <el-table-column
          prop="LeadHot"
          label="LeadHot"
          width="180">
        </el-table-column>
        <el-table-column
          prop="FullLead"
          label="FullLead"
          width="180">
        </el-table-column>
        <el-table-column
          prop="NewLead"
          label="NewLead"
          width="180">
        </el-table-column>
        <el-table-column
          prop="NewLeadF1"
          label="NewLeadF1"
          width="180">
        </el-table-column>
        <el-table-column
          prop="NewFullLead"
          label="NewFullLead"
          width="180">
        </el-table-column>
        <el-table-column
          prop="NewPerson"
          label="NewPerson"
          width="180">
        </el-table-column>
        <el-table-column
          prop="lead_uu"
          label="lead_uu"
          width="180">
        </el-table-column>

      </el-table>
    </template>
  </div>
</template>


<script>

export default {
  components: {},
  props: ['dataDays', 'baners', 'isSum', 'projectData', 'dayData','deviceSum'],
  computed: {},

  data() {
    return {
      data_day: [],
    };
  },
  watch: {},
  methods: {
    getDeviceType(banerId) {
      let result;
      if (!this.isSum && this.dayData === false) {
        this.baners.map((baner) => {
          if (baner.BannerID === banerId) {
            result = baner['deviceType'];
          }
        });
      }else{
        if(this.dayData !== false){
          this.baners.map((baner) => {
            if (baner.BannerID === banerId) {
              result = baner.days[this.dayData];
            }
          });
        }else {
          result = this.deviceSum;
        }
      }
      return result;
    },
    getBaanersValue(banerId, key) {
      let result;

      this.baners.map((baner) => {
        if (this.dayData === false && baner.BannerID === banerId) {
          result = baner[key];
        } else if (this.dayData !== false && baner.BannerID === banerId) {
          const day = this.dayData;
          result = baner['days'][day][key];
        }
      });

      return result;
    },

    getProjectData(projectId, key) {
      let result;
      this.projectData.map((project) => {
        if (project.project_id === projectId) {
          result = project[key];
        }
      });
      return result;
    },
  },
  created() {
  },
  beforeDestroy: function () {
  },
};
</script>
<style>
.devices td, .devices th {
  padding: 10px;
  border-bottom: 1px solid #EBEEF5;

}
</style>
