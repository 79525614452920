<template>
  <div class="modal-wrapper">
    <div class="row header-row">
      <div class="col-sm-8">
        <h5 class="title">Statystyki mailingów</h5>
      </div>
      <div class="col-12">
        <b>[{{mailingStats.BannerID}}] {{mailingStats.BannerName}}</b>
      </div>
    </div>
    <div class="close-button">
      <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer;" aria-hidden="true"></i>
    </div>
    <div class="card" v-for="item in tableData" :key="item[0].event_date">
      <div class="card-header">
        {{ item[0].event_date }}
      </div>
      <div class="card-body row">
        <el-table :data="item">
          <el-table-column label="mobile_click" property="mobile_click" min-width="160" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">

            <template v-slot="props">
              {{ getPercentFromSend(props.row.mobile_click) }}
            </template>
          </el-table-column>
          <el-table-column label="mobile_display" property="mobile_display" min-width="180" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.mobile_display) }}
            </template>
          </el-table-column>
          <el-table-column label="desktop_click" property="desktop_click" min-width="180" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.desktop_click) }}
            </template>
          </el-table-column>
          <el-table-column label="desktop_display" property="desktop_display" min-width="190" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.desktop_display) }}
            </template>
          </el-table-column>
          <el-table-column label="bot_click" property="bot_click" min-width="160" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.bot_click) }}
            </template>
          </el-table-column>
          <el-table-column label="bot_display" property="bot_display" min-width="160" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.bot_display) }}
            </template>
          </el-table-column>
          <el-table-column label="inne_click" property="inne_click" min-width="160" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.inne_click) }}
            </template>
          </el-table-column>
          <el-table-column label="inne_display" property="inne_display" min-width="160" align="left"
                           sortable="custom"></el-table-column>
          <el-table-column label="%" align="left" sortable="custom">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.inne_display) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>


  </div>
</template>

<script>

// event bus
import {eventBus} from "src/main";
import utils from "src/utils";

import * as priv from 'src/privileges.js';

export default {
  components: {},
  data() {
    return {}
  },
  props: ['mailingStats'],
  computed: {
    tableData() {
      console.log(this.mailingStats.days);
      return Object.keys(this.mailingStats.days).map((key) => [this.mailingStats.days[key]]);
    },
    sended(){
      return this.mailingStats.sended;
    },


  },
  methods: {
    getPercentFromSend(amound) {
      if (this.sended > 0 && amound > 0) {
        return ((amound / this.sended) * 100).toFixed(2) + '%';
      } else {
        return '';
      }
    },
  },
  created() {
  },
};
</script>

<style lang="css" scoped>
.center {
  display: flex;
  margin: auto;
  justify-content: center;
  align-content: center;
}

.card {
  margin: 0;
  display: flex;
}

.header-row {
  padding-top: 15px;
  padding-left: 15px;
}

.modal-wrapper {
  position: relative;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
