import { render, staticRenderFns } from "./mhDict.vue?vue&type=template&id=dd6107d6&"
import script from "./mhDict.vue?vue&type=script&lang=js&"
export * from "./mhDict.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports