<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card" style="margin-top: 10px">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-sm-left col-md-4 text-md-left MailingStats__dateRange">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="-"
              start-placeholder="Data początkowa"
              end-placeholder="Data końcowa"
              :picker-options="pickerOptions"
              :clearable="false"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
            >
            </el-date-picker>
          </div>
          <div class="col-sm-4">
            <div>
              <label>Rodzaj wysyłki</label>
            </div>
            <el-radio-group v-model="mailingType" size="medium">
              <el-radio-button label="Wewnętrzne"></el-radio-button>
              <el-radio-button label="Zewnętrzne"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-sm-4 right-bottom">
            <p-button class="MailingStats__buttonsMargin" type="success" @click="loadStats">Statystyki</p-button>
            <!--p-button class="MailingStats__buttonsMargin" type="success" @click="">Wykresy</p-button-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label>Filtr banerów</label>
            <fg-input
              class="MailingStats__banner_filter"
              placeholder="Filtruj po nazwie"
              name="banner_filter"
              data-vv-as="Filtr banerów"
              v-model="filter.banner_filter"
              v-validate="filterValidation.banner_filter"
              :error="getError('banner_filter')"
            >
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Baner</label>
            <fg-input>
              <el-select
                v-model="filter.banners"
                name="banners"
                filterable
                remote
                reserve-keyword
                clearable
                multiple
                :remote-method="remoteSearch"
                :loading="loading"
              >
                <el-option
                  v-for="banner in bannerConfig"
                  :label="'[' + banner.BannerID + '] ' + banner.BannerName"
                  :value="banner.BannerID"
                  :key="banner.BannerID"
                ></el-option>
              </el-select>
            </fg-input>
          </div>
          <div class="col-md-4">
            <label>Projekt</label>
            <fg-input>
              <el-select v-model="filter.projects" name="projects" filterable clearable multiple>
                <el-option-group v-for="group in projectDict" :key="group.label" :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.project_id"
                    :label="item.project_name + ' [' + item.project_id + ']'"
                    :value="item.project_id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </fg-input>
          </div>
        </div>
      </div>
      <hr/>
      <h6>
        <div class="card-footer" style="display: flex; justify-content: space-between">
          <span>Przedział dat:</span>
          <span>{{ dateRange[0] === "" ? "Wybierz zakres dat" : dateFromTitle + " - " + dateToTitle }}</span>
        </div>
        <div class="card-footer count-footer" style="display: flex; justify-content: space-between">
          <span>Suma wysłanych:</span>
          <span>{{ sumSent | formatNumber }}</span>
        </div>
      </h6>
    </div>

    <div class="card">
      <div class="card-body row">
        <div class="col-sm-3">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="col-sm-6 text-center">

        </div>
        <div class="col-sm-3">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Szukaj" v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split"></fg-input>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <el-table
          :data="queriedData"
          style="width: 100%"
          @cell-click="cellClick"
          :cell-style="colStyle"
          :header-cell-style="headerStyle"
          :default-sort="{ prop: 'banner_id', order: 'descending' }"
        >

          <el-table-column label="Projekt" prop="ProjectID" min-width="210" align="left" sortable>
            <template v-slot="props">
              {{ getProjectData(props.row.ProjectID, 'project_name') }}
            </template>
          </el-table-column>
          <el-table-column label="Banner" prop="Banner" min-width="350"  align="left" sortable>
            <template v-slot="props">

              {{ "[" + props.row.BannerID + "] " + props.row.BannerName }}

            </template>
          </el-table-column>
          <el-table-column label="Data Wysłania" prop="DataWyslania" min-width="200" align="left"
                           sortable></el-table-column>
          <el-table-column label="Lista" prop="listEmail" min-width="200" align="left"
                           sortable></el-table-column>
          <el-table-column label="mobile_click" prop="mobile_click" min-width="160" align="left"
                           sortable></el-table-column>
          <el-table-column label="%" align="left" prop="mobile_click">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.mobile_click) }}
            </template>
          </el-table-column>
          <el-table-column label="mobile_display" prop="mobile_display" min-width="160" align="left"
                           sortable></el-table-column>
          <el-table-column label="%" align="left">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.mobile_display) }}
            </template>
          </el-table-column>
          <el-table-column label="desktop_click" prop="desktop_click" min-width="160" align="left"
                           sortable></el-table-column>
          <el-table-column label="%" align="left">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.desktop_click) }}
            </template>
          </el-table-column>
          <el-table-column label="desktop_display" prop="desktop_display" min-width="170" align="left"
                           sortable></el-table-column>
          <el-table-column label="%" align="left">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.desktop_display) }}
            </template>
          </el-table-column>
          <el-table-column label="bot_click" prop="bot_click" min-width="160" align="left"
                           sortable></el-table-column>
          <el-table-column label="%" align="left">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.bot_click) }}
            </template>
          </el-table-column>
          <el-table-column label="bot_display" prop="bot_display" min-width="160" align="left"
                           sortable></el-table-column>
          <el-table-column label="%" align="left">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.bot_display) }}
            </template>
          </el-table-column>
          <el-table-column label="inne_click" prop="inne_click" min-width="160" align="left"></el-table-column>
          <el-table-column label="%" align="left">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.inne_click) }}
            </template>
          </el-table-column>
          <el-table-column label="inne_display" prop="inne_display" min-width="160" align="left"
                           sortable></el-table-column>
          <el-table-column label="%" align="left">
            <template v-slot="props">
              {{ getPercentFromSend(props.row.sended, props.row.inne_display) }}
            </template>
          </el-table-column>


        </el-table>
      </div>
      <div class="card-body row">
        <div class="col-sm-6 pagination-info">
          <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                        :total="pagination.total"></p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library

import {DatePicker, Select, Option, OptionGroup, Tooltip, RadioButton, RadioGroup} from "element-ui";

// ui components
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions

import {LOAD_BANNER_CONFIG, BANNER_CONFIG_INVALID} from "src/store/actions/stats/banner_config";
import {LOAD_PROJECT_DICT, PROJECT_DICT_INVALID} from "src/store/actions/questions/project_dict";


import MailingStatsDevicesModal from "@/components/Utils4/stats/mailings/MailingStatsDevicesModal.vue";

// event bus
import {eventBus} from "src/main";
import utils from "src/utils";

import {
  BANERS_STATS_DEVICES_INVALID,
  BANERS_STATS_DEVICES_LOAD,
  BANERS_STATS_DEVICES_OB_NAME,
  BANERS_STATS_DEVICES_GETTER_NAME
} from "@/store/actions/stats/baners_stats_devices";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup,
    [Tooltip.name]: Tooltip,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    PPagination,
  },
  data() {
    return {
      loading: false,
      filter: {
        banners: [],
        banner_filter: "",
        projects: [],
      },
      filterValidation: {
        banner_filter: {
          regex: "^([0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ._-]+)$",
          max: 32,
        },
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      rowCheck: {},
      checkedRows: {},
      checkAll: false,
      showButton: false,
      sort: {
        prop: "bannerid",
        order: "ascending",
      },
      searchQuery: "",
      propsToSearch: ["banner_id", "banner_name"],
      mailingType: "Wewnętrzne",
      dateRange: ["", ""],
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Dzisiaj",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni tydzień",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatni miesiąc",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ostatnie 3 miesiące",
            onClick(picker) {
              const end = moment(utils.today()).format("YYYY-MM-DD 23:59:59");
              const start = utils.today();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Poprzedni miesiąc",
            onClick(picker) {
              const end = utils.today();
              const start = utils.today();
              start.setDate(1);
              start.setMonth(start.getMonth() - 1);
              end.setDate(0);
              end.setHours(23, 59, 59);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      questionSelectWidth: null,
    };
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    dateFromTitle() {
      moment.locale("pl");
      return moment(this.dateRange[0]).format("dddd, Do MMMM YYYY");
    },
    dateToTitle() {
      moment.locale("pl");
      return moment(this.dateRange[1]).format("dddd, Do MMMM YYYY");
    },
    /**
     * returns first day of the previous month
     */
    defaultDate() {
      return moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    },
    sumSent() {
      let cnt = 0;

      if (this.mailingStats && Object.values(this.mailingStats).length > 0) {
        Object.values(this.mailingStats).forEach((row) => {
          cnt += row.all_sent;
        });
      }

      return cnt;
    },
    devicesStats() {
      return this.$store.getters[BANERS_STATS_DEVICES_GETTER_NAME].data.baners;
    },
    tableData() {
      if (this.$store.state.baners_stats_devices.loading_status !== "success") return [];
      return this.devicesStats;
    },
    queriedData() {
      let result = [];

      result = this.tableData;
      this.pagination.total = this.tableData.length;


      return result.slice(this.from, this.to);;
    },
    mailingStats() {
      return this.$store.getters.getMailingStats;
    },
    bannerConfig() {
      let data = Object.values(this.$store.getters.getBannerConfig);
      data.sort((a, b) => b.BannerID - a.BannerID);
      return data;
    },
    projectList() {
      let result = Object.values(this.$store.getters.getProjectDict);
      return result;
    },
    projectDict() {
      // lista projektów do wybrania z listy
      let projects = this.projectList
      let projectObj = {
        options: [
          {
            label: "MM",
            options: [],
          },
          {
            label: "4M",
            options: [],
          },
        ],
      };
      projects.map((x) => {
        if (x.owner === "MM") {
          projectObj.options[0].options.push(x);
        } else {
          projectObj.options[1].options.push(x);
        }
      });

      projectObj.options[0].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));
      projectObj.options[1].options.sort((a, b) => (a.project_name.toLowerCase() > b.project_name.toLowerCase() ? 1 : -1));

      return projectObj.options;
    },

  },
  methods: {
    cellClick(row, column) {
      console.log('ppp',column);
      if (column.property === "Banner") {
        this.showModal(row);
      }
    },
    showModal(mailingStats) {
      // wyświetlenie modala szczegółów
      this.$modal.show(
        MailingStatsDevicesModal,
        {
          mailingStats: mailingStats,
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(MailingStatsDevicesModal);
              },
            },
          ],
        },
        {
          name: "MailingStatsDevicesModal",
          draggable: false,
          scrollable: true,
          width: "80%",
          height: "auto",
          maxHeight: 800,
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: true,
        },
      );
    },
    getPercentFromSend(send, amound) {
      if (send > 0 && amound > 0) {
        return ((amound / send) * 100).toFixed(2) + '%';
      } else {
        return '';
      }
    },
    async searchBanners(searchQuery) {
      this.loading = true;
      await this.$store.dispatch(LOAD_BANNER_CONFIG, {searchQuery}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
      this.$store.commit(BANNER_CONFIG_INVALID);
    },
    getProjectData(projectId, key) {
      let result;
      this.projectList.map((project) => {
        if (project.project_id === projectId) {
          result = project[key];
        }
      });
      return result;
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    remoteSearch(query) {
      if (query !== "" && query.length >= 3) {
        setTimeout(() => {
          this.searchBanners(query);
        }, 1000);
      }
    },
    async loadData(force) {
      this.loading = true;
      if (force) {
        // this.$store.commit(SETTLEMENTS_INVALID);
      }

      await this.$store.dispatch(LOAD_PROJECT_DICT, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    async loadStats(force) {
      if (this.dateRange[0] === "") {
        eventBus.$emit("notify", {
          message: "Należy wybrać przedział czasowy!",
          type: "warning",
        });
        return;
      }

      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return;
      }

      if (force) {
        this.$store.commit(BANERS_STATS_DEVICES_INVALID);
      }
      this.loading = true;

      if (force && this.dateRange[0] !== "") {
        let date = {
          date_from: moment(this.dateRange[0]).format("YYYY-MM-DD"),
          date_to: moment(this.dateRange[1]).format("YYYY-MM-DD"),
        };

        const filters = Object.assign({}, date, this.filter);
        filters.mailingType = this.mailingType;

        await this.$store.dispatch(BANERS_STATS_DEVICES_LOAD, filters).catch((error) => {
          eventBus.$emit("notify", {
            message: "Błąd wczytywania danych!",
            type: "warning",
          });
        });
        this.loading = false;
      }
    },
    colStyle() {
      return "font-size: 12px;";
    },
    headerStyle() {
      return "font-size: 12px;";
    },
  },
  mounted() {
  },
  created() {
    this.loadData(false);
    eventBus.$on("forceReload", () => {
      this.loadStats(true);
    });
  },
  beforeDestroy: function () {
    this.$store.commit(BANERS_STATS_DEVICES_INVALID);
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="css" scoped>
.right-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.MailingStats__dateRange {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 10px 0;
}

.MailingStats__buttonsMargin {
  margin-left: 10px;
  margin-right: 10px;
}

.count-footer {
  font-size: 12px;
  padding-top: 3px;
}
</style>
