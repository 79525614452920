import storeGen from "@/store/storeGenerator";

import { AUTH_LOGOUT } from "@/store/actions/auth";
import {BASE_API} from "@/store/config";

import {
  BANERS_STATS_DEVICES_GETTER_NAME,
  BANERS_STATS_DEVICES_INVALID,
  BANERS_STATS_DEVICES_LOAD,
  BANERS_STATS_DEVICES_LOADING,
  BANERS_STATS_DEVICES_LOADING_ERROR,
  BANERS_STATS_DEVICES_LOADING_SUCCESS,
  BANERS_STATS_DEVICES_OB_NAME
} from "@/store/actions/stats/baners_stats_devices";

const storeObjectConfig = {
  objectName: BANERS_STATS_DEVICES_OB_NAME,
  getterName: BANERS_STATS_DEVICES_GETTER_NAME,
  loadActionName: BANERS_STATS_DEVICES_LOAD,
  loadingMutationName: BANERS_STATS_DEVICES_LOADING,
  loadingSuccessMutationName: BANERS_STATS_DEVICES_LOADING_SUCCESS,
  loadingErrorMutationName: BANERS_STATS_DEVICES_LOADING_ERROR,
  invalidMutationName: BANERS_STATS_DEVICES_INVALID,
  logoutMutationName: AUTH_LOGOUT,
  apiUrl: BASE_API,
  validPeriod: 10, // minutes
  recordType: "json",
  loadParams: {
    url: "/stats/banersStats/devices",
    method: "post", // {'get','post','put'}
  },
};

const state = storeGen.stateGenerator(storeObjectConfig);
const getters = storeGen.gettersGenerator(storeObjectConfig);
const actions = storeGen.actionsGenerator(storeObjectConfig);
const mutations = storeGen.mutationsGenerator(storeObjectConfig);
export default {
  state,
  getters,
  actions,
  mutations,
};
