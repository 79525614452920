<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h5 class="card-title">Reguły deduplikacji sprzedaży</h5>
      </div>
      <div class="col-sm-12 col-md-4 text-md-right">
        <fg-input
          class="input-sm"
          id="deduplication_search_input"
          placeholder="Szukaj"
          v-model="searchQuery"
          addon-right-icon="nc-icon nc-zoom-split"
        >
        </fg-input>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <el-table :data="tableData" style="width: 100%" size="mini">
          <el-table-column type="expand">
            <template v-slot="props">
              <div class="expand-column">
                <div style="min-width: 200px">
                  <div
                    v-for="(days, client) in props.row.in_deduplication"
                    :key="'in_deduplication_' + props.row.client_id + client"
                    class="deduplicationRow"
                  >
                    [{{ client }}] {{ deduplicationClients[client].client_name }} ({{ deduplicationClients[client].group_name }}):
                    <b>{{ days }}</b> dni
                  </div>
                </div>
                <div style="min-width: 100px"></div>
                <div style="min-width: 200px">
                  <div
                    v-for="(days, client) in props.row.deduplication"
                    :key="'deduplication_' + props.row.client_id + client"
                    class="deduplicationRow"
                  >
                    [{{ client }}] {{ deduplicationClients[client].client_name }} ({{ deduplicationClients[client].group_name }}):
                    <b>{{ days }}</b> dni
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="W deduplikacji" min-width="200" property="in_deduplication_count" align="center"></el-table-column>
          <el-table-column label="Sprzedaż" min-width="200" property="client_name" align="center">
            <template v-slot="props"> [{{ props.row.client_id }}] {{ props.row.client_name }} </template>
          </el-table-column>
          <el-table-column label="Deduplikacja" min-width="200" property="deduplication_count" align="center"></el-table-column>
          <el-table-column align="center" width="60">
            <template slot="header">
              <p-button type="success" icon round @click="handleAdd">
                <i id="deduplication_form_button" class="fa fa-plus"></i>
              </p-button>
            </template>
            <template v-slot="props">
              <p-button type="success" size="sm" icon :id="'deduplication_edit_button' + props.$index" @click="handleEdit(props.$index, props.row)">
                <i class="fa fa-edit"></i>
              </p-button>
            </template>
          </el-table-column>
          <el-table-column align="center" width="60">
            <template v-slot="props">
              <p-button type="danger" size="sm" icon :id="'deduplication_delete_button' + props.$index" @click="handleDelete(props.row)">
                <i class="fa fa-trash"></i>
              </p-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"; // time manipulation library
import Swal from "sweetalert2";

import * as priv from "src/privileges.js";

// event bus
import { eventBus } from "src/main";

import { Tooltip } from "element-ui";

import { DEDUPLICATION_INVALID, LOAD_DEDUPLICATION, DELETE_DEDUPLICATION } from "src/store/actions/sales/deduplication";
import { LOAD_DEDUPLICATION_CLIENTS, DEDUPLICATION_CLIENTS_INVALID } from "src/store/actions/sales/deduplication_clients";
import { LOAD_DEDUPLICATION_ACTIVE_CLIENTS, DEDUPLICATION_ACTIVE_CLIENTS_INVALID } from "src/store/actions/sales/deduplication_active_clients";

import DeduplicationModal from "src/components/Utils4/sales/deduplication/DeduplicationModal.vue";

export default {
  components: {
    [Tooltip.name]: Tooltip,
  },
  data() {
    return {
      loading: false,
      searchQuery: "",
      propsToSearch: ["client_name", "client_id", "group_name", "partner_name"],
    };
  },
  methods: {
    initLoad() {
      this.$store.dispatch(LOAD_DEDUPLICATION_CLIENTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_DEDUPLICATION_ACTIVE_CLIENTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_DEDUPLICATION, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
    },
    async loadData() {
      this.loading = true;

      this.$store.commit(DEDUPLICATION_INVALID);
      this.$store.commit(DEDUPLICATION_ACTIVE_CLIENTS_INVALID);
      this.$store.commit(DEDUPLICATION_CLIENTS_INVALID);

      await this.$store.dispatch(LOAD_DEDUPLICATION, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_DEDUPLICATION_ACTIVE_CLIENTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_DEDUPLICATION_CLIENTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.loading = false;
    },
    handleEdit(index, row) {
      this.$router.push("/sales/deduplication_form/" + row.client_id);
    },
    handleAdd() {
      this.$modal.show(
        DeduplicationModal,
        {
          mode: "deduplication",
          isNew: true,
          parent: "Deduplication",
          buttons: [
            {
              title: "Zamknij",
              handler: () => {
                this.$modal.hide(DeduplicationModal);
              },
            },
          ],
        },
        {
          draggable: false,
          scrollable: false,
          width: "800px",
          height: "680px",
          pivotX: 0.5,
          pivotY: 0.6,
          adaptive: false,
        },
      );
    },
    handleDelete({ client_id }) {
      const model = {
        client_id,
      };

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: `Deaktywacja klienta [${client_id}] ${this.deduplicationClients[client_id].client_name}`,
          text: "Zostaną deaktywowane wszystkie deduplikacje powiązane z tym klientem",
          showCancelButton: true,
          confirmButtonText: "Deaktywuj",
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then(async (res) => {
          if (res.isDismissed) {
            return;
          }
          if (res.isConfirmed) {
            await this.$store.dispatch(DELETE_DEDUPLICATION, model).catch((error) => {
              eventBus.$emit("notify", {
                message: "Błąd zapisu danych!",
                type: "warning",
              });
            });

            if (this.$store.getters.getDeduplicationDeletingStatus === "deleted") {
              eventBus.$emit("notify", {
                message: "Rekord został usunięty",
                type: "success",
              });
              this.loadData();
            }
          }
        })
        .catch(() => {
          return;
        });
    },
  },
  computed: {
    deduplication() {
      return this.$store.getters.getDeduplication;
    },
    deduplicationActiveClients() {
      return this.$store.getters.getDeduplicationActiveClients;
    },
    deduplicationClients() {
      return this.$store.getters.getDeduplicationClients;
    },
    tableData() {
      if (this.$store.state.deduplication.loading_status === "success" && this.$store.state.deduplication_clients.loading_status === "success") {
        const getter = this.$store.getters.getDeduplication;
        let tableData = [];

        let deduplicationIds = [...Object.keys(getter.deduplication), ...Object.keys(getter.in_deduplication)];

        const uniqueDeduplicationIds = deduplicationIds.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        for (let i in uniqueDeduplicationIds) {
          const id = uniqueDeduplicationIds[i];

          let tempObj = JSON.parse(JSON.stringify(this.deduplicationClients[id]));
          if (getter.deduplication[id]) {
            tempObj.deduplication = getter.deduplication[id];
            tempObj.deduplication_count = Object.keys(getter.deduplication[id]).length;
          } else {
            tempObj.deduplication = {};
            tempObj.deduplication_count = 0;
          }
          if (getter.in_deduplication[id]) {
            tempObj.in_deduplication = getter.in_deduplication[id];
            tempObj.in_deduplication_count = Object.keys(getter.in_deduplication[id]).length;
          } else {
            tempObj.in_deduplication = {};
            tempObj.in_deduplication_count = 0;
          }

          tableData.push(tempObj);
        }

        tableData.sort((a, b) => (a.client_name.toLowerCase() > b.client_name.toLowerCase() ? 1 : -1));

        tableData = tableData.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            let rowValue = row[key]?.toString();
            let rowValueLowerCase = row[key]?.toString()?.toLowerCase();
            if ((rowValue && rowValue.includes(this.searchQuery)) || (rowValueLowerCase && rowValueLowerCase.includes(this.searchQuery))) {
              isIncluded = true;
            }
          }
          return isIncluded;
        });

        return tableData;
      } else {
        return [];
      }
    },
  },
  created() {
    this.initLoad();
    eventBus.$on("forceReload", () => {
      this.loadData();
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>
<style scoped>
.expand-column {
  margin-left: 60px;
  display: flex;
  justify-content: space-between;
}

.deduplicationRow {
  margin-bottom: 3px;
}
</style>
