<template>
  <div>
    <div class="row headerRow">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">Statystyki mailingów</h5>
      </div>
      <div class="col-md-6 ml-auto">
        <el-menu default-active="efficiency" class="el-menu-demo" background-color="#F4F3EF" mode="horizontal" @select="menuSelect">
          <el-menu-item index="efficiency">Wysyłki</el-menu-item>
          <el-menu-item index="devices">Urządzenia</el-menu-item>
          <el-menu-item index="domain">Domeny</el-menu-item>
          <el-menu-item index="demography">Demografia</el-menu-item>
          <el-menu-item index="hour">Godziny</el-menu-item>
        </el-menu>
      </div>
    </div>

    <div v-if="selectedMenu === 'domain'">
      <appDomainStats></appDomainStats>
    </div>
    <div v-else-if="selectedMenu === 'demography'">
      <appDemographyStats></appDemographyStats>
    </div>
    <div v-else-if="selectedMenu === 'hour'">
      <appHourStats></appHourStats>
    </div>
    <div v-else-if="selectedMenu === 'efficiency'">
      <appEfficiencyStats></appEfficiencyStats>
    </div>
    <div v-else-if="selectedMenu === 'devices'">
      <app-devices-stats></app-devices-stats>
    </div>
  </div>
</template>
<script>
import { Menu, MenuItem } from "element-ui";

import DomainStats from "src/components/Utils4/stats/mailings/DomainStats.vue";
import DevicesStats from "src/components/Utils4/stats/mailings/DevicesStats.vue";
import DemographyStats from "src/components/Utils4/stats/mailings/DemographyStats.vue";
import HourStats from "src/components/Utils4/stats/mailings/HourStats.vue";
import EfficiencyStats from "src/components/Utils4/stats/mailings/EfficiencyStats.vue";

export default {
  components: {
    [Menu.name]: Menu,
    [MenuItem.name]: MenuItem,
    appDomainStats: DomainStats,
    appDevicesStats:DevicesStats,
    appDemographyStats: DemographyStats,
    appHourStats: HourStats,
    appEfficiencyStats: EfficiencyStats,
  },
  data() {
    return {
      selectedMenu: "efficiency",
    };
  },
  methods: {
    menuSelect(index, indexPath) {
      this.selectedMenu = index;
    },
  },
};
</script>
<style scoped>
.el-menu {
  float: right;
  margin-right: 10px;
}

.headerRow {
  margin-bottom: -11px;
}
</style>
